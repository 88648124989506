import React from "react";
import "../styles/Footer.css";
export default function Footer() {
  return (
    <div>
      <footer className="bg-light text-center text-white">
        <div className="container p-4 pb-0">
          <section className="mb-4">
            <a
              className="btn text-white btn-floating m-1"
              href="https://www.facebook.com/siteltelefonia"
              role="button"
            >
              <img src="https://img.icons8.com/dusk/256/facebook-new--v2.png" width={40} />
            </a>

            <a
              className="btn text-white btn-floating m-1 google-bg"
              href="mailto:ventas@siteltelefonia.com"
              role="button"
            >
                <img src="https://img.icons8.com/plasticine/256/gmail.png" width={50} />
            </a>

            <a
              className="btn text-white btn-floating m-1"
              href="https://www.instagram.com/siteltelefonia/"
              role="button"
            >
              <img src="https://img.icons8.com/office/256/instagram-new.png" width={35} />
            </a>
          </section>
        </div>

        <div className="text-center p-3 copy-bg">
          <span>© 2023 Copyright:  </span>
          <a className="text-white" href="">
            Sitel S.R.L
          </a>
        </div>
      </footer>
    </div>
  );
}
