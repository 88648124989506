import React, { useState, useContext, useEffect } from 'react';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { FireContext } from '../context/FireContext'; 
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth'; 
import "../styles/AddProduct.css";

function AddProduct() {
  const [nombre, setName] = useState('');
  const [desc, setDescription] = useState(['']);
  const [destacado, setIsFeatured] = useState(false);
  const [fotos, setPhotos] = useState(['']);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [useNewCategory, setUseNewCategory] = useState(false);
  const { productos } = useContext(FireContext);
  const [existingCategories, setExistingCategories] = useState([]);
  const [user, setUser] = useState(null);

  const allowedEmails = [
    "santiagocabral1990@gmail.com",
    "siteltelefonia22@gmail.com",
    "eze.rc177@gmail.com"
  ]; // Lista de correos permitidos

  const auth = getAuth(); 
  const provider = new GoogleAuthProvider(); 
  const db = getFirestore();
  const productsCollection = collection(db, 'productos');

  useEffect(() => {
    if (productos) {
      const categories = [...new Set(productos.map((producto) => producto.category))];
      setExistingCategories(categories);
    }
  }, [productos]);

  const handleAddDescription = () => setDescription([...desc, '']);
  const handleAddPhoto = () => setPhotos([...fotos, '']);
  const handleRemoveDescription = (index) => setDescription(desc.filter((_, i) => i !== index));
  const handleRemovePhoto = (index) => setPhotos(fotos.filter((_, i) => i !== index));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedCategory = useNewCategory ? newCategory : category;
    if (nombre && selectedCategory && desc.length > 0 && fotos.length > 0) {
      const newProduct = {
        nombre,
        desc,
        destacado,
        fotos,
        category: selectedCategory,
        subCategory: subCategory || null,
      };
      await addDoc(productsCollection, newProduct);
      alert('Producto agregado correctamente');
      setName('');
      setDescription(['']);
      setIsFeatured(false);
      setPhotos(['']);
      setCategory('');
      setSubCategory('');
      setNewCategory('');
    } else {
      alert('Por favor completa todos los campos requeridos');
    }
  };

  // Funciones para inicio y cierre de sesión con Google
  const handleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        if (allowedEmails.includes(result.user.email)) {
          setUser(result.user);
        } else {
          alert("No tienes permiso para agregar productos.");
          signOut(auth);
        }
      })
      .catch((error) => console.error("Error al iniciar sesión con Google:", error));
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => setUser(null))
      .catch((error) => console.error("Error al cerrar sesión:", error));
  };

  return (
    <div className="add-product-container">
      {!user ? (
        <button onClick={handleSignIn} className="sign-in-btn">Iniciar sesión con Google</button>
      ) : (
        <>
          <h2 className="title">Agregar Nuevo Producto</h2>
          <button onClick={handleSignOut} className="sign-out-btn">Cerrar sesión</button>
          <form onSubmit={handleSubmit} className="product-form">
            <div className="form-group">
              <label>Nombre del Producto</label>
              <input
                type="text"
                className="form-input"
                placeholder="Nombre del Producto"
                value={nombre}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Descripción:</label>
              {desc.map((descc, index) => (
                <div key={index} className="description-group">
                  <input
                    type="text"
                    className="form-input"
                    value={descc}
                    placeholder={`Descripción ${index + 1}`}
                    onChange={(e) =>
                      setDescription(
                        desc.map((d, i) => (i === index ? e.target.value : d))
                      )
                    }
                  />
                  {desc.length > 1 && (
                    <button
                      type="button"
                      className="remove-btn"
                      onClick={() => handleRemoveDescription(index)}
                    >
                      Eliminar
                    </button>
                  )}
                </div>
              ))}
              <button type="button" className="add-btn" onClick={handleAddDescription}>
                Agregar Descripción
              </button>
            </div>

            <div className="form-group">
              <label>Fotos:</label>
              {fotos.map((photo, index) => (
                <div key={index} className="photo-group">
                  <input
                    type="text"
                    className="form-input"
                    value={photo}
                    placeholder={`Foto ${index + 1}`}
                    onChange={(e) =>
                      setPhotos(
                        fotos.map((p, i) => (i === index ? e.target.value : p))
                      )
                    }
                  />
                  {fotos.length > 1 && (
                    <button
                      type="button"
                      className="remove-btn"
                      onClick={() => handleRemovePhoto(index)}
                    >
                      Eliminar
                    </button>
                  )}
                </div>
              ))}
              <button type="button" className="add-btn" onClick={handleAddPhoto}>
                Agregar Foto
              </button>
            </div>

            <div className="form-group">
              <label>¿Destacado?</label>
              <input
                type="checkbox"
                checked={destacado}
                onChange={(e) => setIsFeatured(e.target.checked)}
              />
            </div>

            <div className="form-group">
              <label>Categoría</label>
              {!useNewCategory ? (
                <select
                  className="form-input"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">Seleccione una categoría</option>
                  {existingCategories.map((cat, index) => (
                    <option key={index} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  className="form-input"
                  placeholder="Nueva Categoría"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                />
              )}
              <button
                type="button"
                onClick={() => setUseNewCategory(!useNewCategory)}
                className="add-btn"
              >
                {useNewCategory ? 'Usar Categoría Existente' : 'Agregar Nueva Categoría'}
              </button>
            </div>

            <div className="form-group">
              <label>Sub-Categoría (Opcional)</label>
              <input
                type="text"
                className="form-input"
                placeholder="Sub-Categoría"
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
              />
            </div>

            <button type="submit" className="submit-btn">
              Agregar Producto
            </button>
          </form>
        </>
      )}
    </div>
  );
}

export default AddProduct;
