import React from 'react'
import {useState, useEffect, useContext} from 'react';
import { useParams } from 'react-router-dom'
import CardProduct from './componentesProducts/CardProduct'
import {FireContext} from '../context/FireContext';



export default function ProductList() {
  const [busqueda, setBusqueda] = useState("");
  const { category } = useParams();
  const [resultado, setResultado] = useState([]);
  const { productos } = useContext(FireContext);

  useEffect(() => {
    const filtrar = () => {
      if (productos) {
        if (category) {
          setResultado(productos.filter(producto => producto.category === category));
        } else {
          setResultado(productos);
        }
      }
    };
    filtrar();
  }, [category, productos]);


  return (
    <>
      
      {resultado?.length > 0 ?
        <CardProduct productos={resultado} busqueda={busqueda} setBusqueda={setBusqueda} categoria={category}/>
      : 
        <CardProduct productos={productos} busqueda={busqueda} setBusqueda={setBusqueda} categoria={category}/>
      }
       

    </>
  )
}
