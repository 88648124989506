import React from "react";
import "../styles/JoinMyTeam.css";

export default function JoinMyTeam() {
  return (
    <>
      <div className="unite-a-mi-equipo-container">
        <div className="text-unite">
          <img
            className="banner-products"
            src="https://i.ibb.co/1ZwL0Hz/COMO-LLEGAR.jpg"
            alt=""
          />
        </div>
        <div class="video-responsive">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3347.7426276310894!2d-60.64812538792271!3d-32.95780387234342!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b7ab0e7d0ca48d%3A0xbb12d4970a39277b!2sTelefon%C3%ADa%20Sitel!5e0!3m2!1ses!2sar!4v1724725988119!5m2!1ses!2sar"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="contenido-unite">
          <a href="https://wa.link/1lrlbu" target="_blank">
            <button className="button-contacto">Contactate conmigo</button>
          </a>
        </div>
      </div>
    </>
  );
}
