import React, { useState, useContext } from "react";
import { CartContext } from "../componentesCart/CartContext"; // Importa el contexto del carrito
import { Link } from "react-router-dom";
// import CardComboProduct from "./CardComboProduct.jsx";
import "../../styles/CardDetailProducts.css";

export default function CardDetailProduct({ itemDet, productos }) {
  const { addToCart } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  return (
    <div>
      <div className="centerBanner">
        <img
          className="banner-products"
          src="https://i.ibb.co/V2Wj6h2/Banner-de-Linked-In-Tecnolog-a-Abstracto-Azul-y-Blanco.png"
          alt=""
        />
      </div>
      {/* Barra de Navegación */}
      <div className="breadcrumb-container">
        <nav className="breadcrumb-nav">
          <Link to="/productos" className="breadcrumb-link">
            Todos los productos
          </Link>{" "}
          &gt;
          <Link
            to={`/category/${itemDet.category}`}
            className="breadcrumb-link"
          >
            {itemDet.category}
          </Link>{" "}
          &gt;
          <span className="breadcrumb-current">{itemDet.nombre}</span>
        </nav>
      </div>
      <div className="container-detail">
        <div className="detailContainer">
          <div className="detailImg">
            <div className="row">
              <div
                id="carouselExampleCaptions"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  {itemDet.fotos?.map((item, index) => (
                    <button
                      type="button"
                      data-bs-target="#carouselExampleCaptions"
                      data-bs-slide-to={index}
                      aria-label={`Slide ${index}`}
                      className={`${index === 0 ? "active" : ""}`}
                      aria-current={`${index === 0 ? "true" : ""}`}
                    ></button>
                  ))}
                </div>

                <div className="carousel-inner">
                  {itemDet.fotos?.map((item, index) => (
                    <div
                      className={`img-product-detail carousel-item ${
                        index === 0 ? "active" : ""
                      }`}
                    >
                      <img src={item} className="d-block w-100" alt="..." />
                      <div className="carousel-caption d-none d-md-block"></div>
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
          <div className="detailInfo">
            {itemDet.destacado && (
              <div className="producto-destacado">Producto Destacado</div>
            )}
            <h2 className="title-detail-item">{itemDet.nombre}</h2>
            {/* {itemDet.likes &&
              <div className='likes-product'>
                <img className='corazon-like' src="https://img.icons8.com/fluency/256/filled-like.png" width={20} alt="img-corazon" />
                <span className='like-text'>Likes: {itemDet.likes}</span>
              </div>
            } */}
            {/* <div className="box-consultar">
              <a
                className="remove-line"
                href={`https://api.whatsapp.com/send?phone=+543415471572&text=Hola,%20anto!%20Te%20contacto%20porque%20me%20interesa%20el%20producto:%20${itemDet.titleW}%20me%20podr%C3%ADas%20indicar%20el%20precio%20y%20medios%20de%20pago?%20Gracias%20`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="button-consultar">
                  <img
                    className="botonF1"
                    src="https://img.icons8.com/office/256/whatsapp--v1.png"
                    alt="logo-whatsapp"
                  />
                  <span>Consultar por el producto</span>
                </div>
              </a>
            </div> */}

            <div className="cart-controls">
              <div className="quantity-control">
                <button onClick={decreaseQuantity}>-</button>
                <span>{quantity}</span>
                <button onClick={increaseQuantity}>+</button>
              </div>
              <button
                className="add-to-cart"
                onClick={() => addToCart({ ...itemDet, quantity })}
              >
                Añadir al Carrito
              </button>
            </div>

            <div className="desc-producto">
              <h3>Descripción:</h3>
              {itemDet?.desc.map((item) => (
                <p>- {item}</p>
              ))}
            </div>
            {/* {itemDet?.filtroCat === "combos" && 
            <div className='combos-producto'>
              <h3>Este combo contiene:</h3>
              <div className='order-cards-combos'>
                {itemDet.contiene?.map(item => productos.find(producto => producto.id === item))
                .map(item => <CardComboProduct producto={item}/>)
                }
                </div>
              <div>
                
              </div>
            </div>} */}
            <div className="caracteristicas-producto">
              <h3>Características:</h3>
              <div>
                {/* {itemDet.diametro !== "-" && <p className='title-caract'>Diametro: <span>{itemDet.diametro}</span></p>}
                {itemDet.capacidad !== "-" && <p className='title-caract'>Capacidad: <span>{itemDet.capacidad}</span></p>}
                {itemDet.comensales !== "-" && <p className='title-caract'>Comensales: <span>{itemDet.comensales}</span></p>}
                {itemDet.recubrimientoInt !== "-" && <p className='title-caract'>Recubrimiento Interior: <span>{itemDet.recubrimientoInt}</span></p>}
                {itemDet.recubrimientoExt !== "-" && <p className='title-caract'>Recubrimiento Exterior: <span>{itemDet.recubrimientoExt}</span></p>} */}
              </div>
            </div>

            <div className="promos-tarjetas">
              <b>Todas las tarjetas, consulta precios y cuotas sin interés.</b>
              <div className="logos-tarjetas">
                <img
                  src="https://img.icons8.com/color/256/visa.png"
                  alt="img-visa"
                  width={50}
                />
                <img
                  src="https://img.icons8.com/color/256/mastercard.png"
                  alt="img-master-card"
                  width={50}
                />
                <img
                  src="https://img.icons8.com/plasticine/256/amex.png"
                  width={50}
                  alt="img-amex"
                />
                <img
                  src="https://www.pngfind.com/pngs/m/622-6221359_tarjeta-naranja-logo-by-stacey-konopelski-tarjeta-naranja.png"
                  alt="img-tarjeta-naranja"
                  width={50}
                  height={35}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
