import React from "react";
import { Link } from "react-router-dom";
import Carrousel from "./componentesHome/Carrousel";
import FeaturedProducts from "./componentesHome/FeaturedProducts";
import { useEffect, useState, useContext } from "react";
import { getDocs, getFirestore, collection } from "firebase/firestore";
import { FireContext } from "../context/FireContext";
import "../styles/Home.css";
import Loader from "./Loader.jsx";

export default function Home() {
  const { filminas, productos, loading } = useContext(FireContext);
  const [categorias, setCategorias] = useState({});

  useEffect(() => {
    if (productos) {
      const categoriasTemp = {};

      productos.forEach((producto) => {
        if (producto.category) {
          if (!categoriasTemp[producto.category]) {
            categoriasTemp[producto.category] = producto.fotos[0]; // Asignar la primera foto encontrada
          }
        }
      });

      setCategorias(categoriasTemp);
    }
  }, [productos]);

  return (
    <>
      {loading && <Loader />}
      <div className="animate-charcter">
        Consulta ofertas con tu tarjeta bancaria
      </div>

      <div className="centerCarrosel">
        <div className="carrouselMaxW">
          {filminas && <Carrousel filminas={filminas} />}
        </div>
      </div>

      <div className="centerBanner-dos">
        <div className="banner-dos">
          <div className="position-item-banner">
            <img
              src="https://img.icons8.com/?size=100&id=3562&format=png&color=000000"
              className="size-icon-banner"
              alt="..."
            />
            <h3 className="text-cool">Envíos a todo el país</h3>
          </div>
          <div className="position-item-banner">
            <img
              src="https://hardesk-aws-s3.s3.amazonaws.com/imagenes_prod/iconoshome/garantia.svg"
              className="size-icon-banner"
              alt="..."
            />
            <h3 className="text-cool">+50 años de Experiencia</h3>
          </div>
          <div className="position-item-banner">
            <img
              src="https://img.icons8.com/?size=100&id=48852&format=png&color=000000"
              className="size-icon-banner"
              alt="..."
            />
            <h3 className="text-cool">Soporte Técnico</h3>
          </div>
          <div className="position-item-banner">
            <img
              src="https://img.icons8.com/?size=100&id=i7BFTyy4oQTG&format=png&color=000000"
              className="size-icon-banner"
              alt="..."
            />
            <h3 className="text-cool">Última Técnología</h3>
          </div>
        </div>
      </div>

      <div>
        <h2 className="title-home">Productos destacados</h2>
      </div>

      <FeaturedProducts productos={productos} />

      <div>
        <h2 className="title-home">Categorías</h2>

        <div className="container-category">
          {Object.keys(categorias).map((categoria) => (
            <Link
              key={categoria}
              className="card-category"
              to={`/category/${categoria}`}
            >
              <img
                className="img-category"
                src={categorias[categoria]}
                alt={categoria}
              />
              <div className="text-category">{categoria}</div>
            </Link>
          ))}
          {/* <Link className="card-category" to="/category/piezas">
            <img
                className="img-category" src="https://i.ibb.co/Q8QQJdg/pieza-essen.png"
                alt=""
              />
            <div className="text-category">Piezas</div>
            </Link>
            <Link className="card-category" to="/category/complementos">
            <img
                className="img-category"
                src="https://i.ibb.co/1fYPk29/complementos-essen.png"
                alt=""
              />
              <div className="text-category">Complementos</div>
            </Link>
            <Link className="card-category" to="/category/bazar-premium">
            <img
                className="img-category"
                src="https://i.ibb.co/sQHfCcd/bazar-essen.png"
                alt=""
              />
              <div className="text-category">Bazar Premium</div>
            </Link> */}
        </div>
      </div>
    </>
  );
}
