import React from 'react';
import { createContext, useEffect, useState } from 'react';
import { getDocs, getFirestore, collection, doc, updateDoc } from 'firebase/firestore';

export const FireContext = createContext();

const { Provider } = FireContext;

const MyProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [filminas, setFilminas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [recetas, setRecetas] = useState([]);

  const colectionFilFire = "carrousel";
  const colectionProdFire = "productos";
  const colectionRecetas = "recetas";
  const db = getFirestore();
  const filminasColeccion = collection(db, colectionFilFire);
  const productosColeccion = collection(db, colectionProdFire);
  const recetasColeccion = collection(db, colectionRecetas);

  useEffect(() => {
    const fetchData = async () => {
      const filminasData = await getDocs(filminasColeccion);
      setFilminas(filminasData.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchRecetas = async () => {
      const recetasData = await getDocs(recetasColeccion);
      setRecetas(recetasData.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    fetchRecetas();
  }, []);

  useEffect(() => {
    const fetchProductos = async () => {
      const productosData = await getDocs(productosColeccion);
      setProductos(productosData.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      setLoading(false);
    };
    fetchProductos();
  }, []);

  // Función para actualizar productos en Firebase y el estado local
  const updateProducto = async (id, updatedData) => {
    const productDoc = doc(db, 'productos', id);
    await updateDoc(productDoc, updatedData);
    
    // Actualizar el estado local de productos
    setProductos((prevProductos) =>
      prevProductos.map((producto) =>
        producto.id === id ? { ...producto, ...updatedData } : producto
      )
    );
  };

  return (
    <Provider value={{ filminas, productos, setProductos, loading, recetas, updateProducto }}>
      {children}
    </Provider>
  );
};

export default MyProvider;



