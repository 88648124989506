import React, { useRef, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../img/sitel.png";
import "../styles/NavBar.css";
import { FireContext } from "../context/FireContext";
import { CartContext } from "./componentesCart/CartContext";

function NavBar() {
  const { productos } = useContext(FireContext);
  const { cart } = useContext(CartContext);
  const navButton = useRef(null);
  const linksContainerRef = useRef(null);

  function collapseNav() {
    navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
  }

  // Crear un conjunto de categorías dinámicamente
  const categorias = new Set();
  if (productos) {
    productos.forEach(producto => {
      if (producto.category) {
        categorias.add(producto.category);
      }
    });
  }

  return (
    <nav className="navbar navbar-expand-lg styleNav">
      <div className="container-fluid">
        <Link to="/">
          <img
            src={logo}
            onClick={collapseNav}
            className="Logo navbar-brand logoSitel"
            width="100px"
            alt="Logo"
          />
        </Link>
        <button
          ref={navButton}
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          ref={linksContainerRef}
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                onClick={collapseNav}
                to="/"
                className="nav-link active efect-nav"
              >
                Inicio
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                to="/productos"
                className="nav-link dropdown-toggle text-nav efect-nav"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Productos
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link
                    onClick={collapseNav}
                    to="/productos"
                    className="dropdown-item text-nav efect-nav"
                  >
                    Todos los productos
                  </Link>
                </li>
                {[...categorias].map((categoria) => (
                  <li key={categoria}>
                    <Link
                      onClick={collapseNav}
                      to={`/category/${categoria}`}
                      className="dropdown-item text-nav efect-nav"
                    >
                      {categoria}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link
                onClick={collapseNav}
                to="/sobre-nosotros/"
                className="nav-link text-nav efect-nav"
              >
                Sobre nosotros
              </Link>
            </li>
            <li>
              <Link
                onClick={collapseNav}
                to="/como-llegar/"
                className="nav-link text-nav efect-nav"
              >
                Como Llegar
              </Link>
            </li>
          </ul>
          <div className="cart-icon ml-auto">
            <Link to="/cart">
              <img
                src="https://img.icons8.com/?size=100&id=3538&format=png&color=000000"
                alt="cartImage"
                className="cart-img"
              />
              <span>{cart.length}</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
