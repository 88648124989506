import React from "react";
import { Link } from 'react-router-dom';
import "../../styles/FeaturedProducts.css";

export default function FeaturedProducts({ productos }) {
  return (
    <>
      <div className="container-featured-products">
        {productos?.map(
          (producto) =>
            producto.destacado && (
              <div className="cardProducts">
                <Link className="btn-prod-dec" to={"/item/" + producto.id}>
                  <img
                    className="img-card-product"
                    src={producto.fotos[0]}
                    alt=""
                  />
                  <div className="category-product">{producto.category}</div>
                  <div className="title-product">{producto.nombre}</div>

                  {/* <div className="colors-container">
                    <div className="title-colors">Colores Disponibles</div>
                    <div className="colors">
                      {producto.colores.map((color) => (
                        <div className="colores-disp" style={{ backgroundColor: color }}>

                        </div>
                      ))
                      }
                    </div>
                  </div> */}
                </Link>
              </div>
            )
        )}
      </div>
    </>
  );
}
