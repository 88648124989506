import React from 'react'
import "../styles/Loader.css"

export default function Loader() {

  return (
    <div className='itemListLoading'>
        {/* <span class="loader"></span> */}
        <div className='texto-cargando'>C A R G A N D O ...</div>
    </div>
  )
}
