import React, { useContext } from "react";
import { CartContext } from "./componentesCart/CartContext";
import { Link } from 'react-router-dom';
import "../styles/Cart.css";

function Cart() {
  const { cart, removeFromCart, addToCart, clearCart } =
    useContext(CartContext);

  const decreaseQuantity = (item) => {
    if (item.quantity > 1) {
      addToCart({ ...item, quantity: -1 });
    } else {
      removeFromCart(item.id);
    }
  };

  const sendToWhatsApp = () => {
    const baseUrl = "https://wa.me/543413730389?text=";
    let message = "*Hola,*\n\n";
    message += "Necesito un presupuesto por los siguientes productos:\n\n";
    cart.forEach((item) => {
      message += `• *${item.nombre}*\n  Cantidad: ${item.quantity}\n\n`;
    });
    message += "*Gracias.*";
    window.open(baseUrl + encodeURIComponent(message), "_blank");
  };

  const sendToEmail = () => {
    const email = "ventas@siteltelefonia.com"; // Reemplaza con el correo electrónico destino
    const subject = "Solicitud de Presupuesto";
    let body = "Hola,\n\n";
    body += "Necesito un presupuesto por los siguientes productos:\n\n";
    cart.forEach((item) => {
      body += `• ${item.nombre}\n  Cantidad: ${item.quantity}\n\n`;
    });
    body += "Gracias.";

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink, "_blank");
  };

  return (
    <div className="cart-container">
      <div>
        <p>Carrito de Compras</p>
      </div>
      {cart.length === 0 ? (
        <div>
          <p className="centerText">El carrito está vacío</p>
          <Link to="/productos">
          <button className="go-to-products-button">Ver todos los productos</button>
  </Link>
        </div>
      ) : (
        <>
          <div className="cart-list">
            {cart.map((item) => (
              <div key={item.id} className="cart-item">
                <div className="centerItems">
                  <img
                    src={item.fotos[0]}
                    alt={item.nombre}
                    className="cart-item-img"
                  />
                </div>
                <div className="cart-item-name">{item.nombre}</div>

                <div className="cart-item-controls">
                  <button onClick={() => decreaseQuantity(item)}>-</button>
                  <span>{item.quantity}</span>
                  <button onClick={() => addToCart({ ...item, quantity: 1 })}>
                    +
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => removeFromCart(item.id)}
                    className="remove-btn"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ))}
          </div>
          <button onClick={clearCart} className="clear-cart-btn">
            Vaciar Carrito
          </button>
          <button onClick={sendToWhatsApp} className="send-whatsapp-btn">
            Enviar a WhatsApp
          </button>

          <button onClick={sendToEmail} className="send-email-btn">
            Pedir Presupuesto por Email
          </button>
        </>
      )}
    </div>
  );
}

export default Cart;
