import React, { useState, useContext, useEffect } from "react";
import { FireContext } from "../context/FireContext";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import "../styles/ManageProducts.css";

function ManageProducts() {
  const { productos, updateProducto } = useContext(FireContext); // Se añade updateProducto del contexto
  const [editProductId, setEditProductId] = useState(null);
  const [editedProduct, setEditedProduct] = useState({});
  const [user, setUser] = useState(null);
  const [newCategory, setNewCategory] = useState("");
  const [useNewCategory, setUseNewCategory] = useState(false);

  // Lista de correos permitidos para editar productos
  const allowedEmails = [
    "santiagocabral1990@gmail.com",
    "siteltelefonia22@gmail.com",
    "eze.rc177@gmail.com"
  ];

  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const handleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => setUser(result.user))
      .catch((error) =>
        console.error("Error al iniciar sesión con Google:", error)
      );
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => setUser(null))
      .catch((error) => console.error("Error al cerrar sesión:", error));
  };

  useEffect(() => {
    if (user && !allowedEmails.includes(user.email)) {
      alert("No tienes permiso para gestionar productos.");
      handleSignOut();
    }
  }, [user]);

  const handleEdit = (product) => {
    setEditProductId(product.id);
    setEditedProduct(product);
    setUseNewCategory(false);
  };

  const handleUpdate = async () => {
    await updateProducto(editProductId, editedProduct); // Utiliza la función updateProducto del contexto
    alert("Producto actualizado correctamente");
    setEditProductId(null);
  };

  const handleDelete = async (productId) => {
    // La lógica de eliminación se mantendría aquí, sin cambios
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct({ ...editedProduct, [name]: value });
  };

  // Función para agregar un nuevo párrafo de descripción
  const addNewDescription = () => {
    setEditedProduct({ ...editedProduct, desc: [...editedProduct.desc, ""] });
  };

  // Función para eliminar una descripción
  const removeDescription = (index) => {
    const updatedDescriptions = editedProduct.desc.filter(
      (_, i) => i !== index
    );
    setEditedProduct({ ...editedProduct, desc: updatedDescriptions });
  };

  const handleDescriptionChange = (e, index) => {
    const updatedDescriptions = editedProduct.desc.map((d, i) =>
      i === index ? e.target.value : d
    );
    setEditedProduct({ ...editedProduct, desc: updatedDescriptions });
  };

  // Función para agregar una nueva foto
  const addNewPhoto = () => {
    setEditedProduct({ ...editedProduct, fotos: [...editedProduct.fotos, ""] });
  };

  // Función para eliminar una foto
  const removePhoto = (index) => {
    const updatedPhotos = editedProduct.fotos.filter((_, i) => i !== index);
    setEditedProduct({ ...editedProduct, fotos: updatedPhotos });
  };

  const handlePhotoChange = (e, index) => {
    const updatedPhotos = editedProduct.fotos.map((f, i) =>
      i === index ? e.target.value : f
    );
    setEditedProduct({ ...editedProduct, fotos: updatedPhotos });
  };

  return (
    <div className="manage-products-container">
      {!user ? (
        <button onClick={handleSignIn} className="sign-in-btn">
          Iniciar sesión con Google
        </button>
      ) : (
        <>
          <h2 className="title">Administrar Productos</h2>
          <button onClick={handleSignOut} className="sign-out-btn">
            Cerrar sesión
          </button>

          {productos && productos.length > 0 ? (
            <table className="products-table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Categoría</th>
                  <th>Destacado</th>
                  <th className="description-column">Descripción</th>
                  <th className="photos-column">Fotos</th>
                  <th>Sub-Categoría</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {productos.map((product) => (
                  <tr key={product.id}>
                    {editProductId === product.id ? (
                      <>
                        <td>
                          <input
                            type="text"
                            name="nombre"
                            value={editedProduct.nombre}
                            onChange={handleInputChange}
                          />
                        </td>
                        <td>
                          {!useNewCategory ? (
                            <input
                              type="text"
                              name="category"
                              value={editedProduct.category}
                              onChange={handleInputChange}
                            />
                          ) : (
                            <input
                              type="text"
                              name="category"
                              value={newCategory}
                              onChange={(e) => setNewCategory(e.target.value)}
                            />
                          )}
                          <button
                            onClick={() => setUseNewCategory(!useNewCategory)}
                          >
                            {useNewCategory
                              ? "Usar Categoría Existente"
                              : "Agregar Nueva Categoría"}
                          </button>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            name="destacado"
                            checked={editedProduct.destacado}
                            onChange={(e) =>
                              setEditedProduct({
                                ...editedProduct,
                                destacado: e.target.checked,
                              })
                            }
                          />
                        </td>
                        <td>
                          {editedProduct.desc.map((descc, index) => (
                            <div key={index}>
                              <input
                                type="text"
                                name="desc"
                                value={descc}
                                onChange={(e) =>
                                  handleDescriptionChange(e, index)
                                }
                              />
                              <button
                                onClick={() => removeDescription(index)}
                                className="remove-desc-btn"
                              >
                                Eliminar Descripción
                              </button>
                            </div>
                          ))}
                          <button
                            onClick={addNewDescription}
                            className="add-desc-btn"
                          >
                            Agregar Descripción
                          </button>
                        </td>
                        <td>
                          {editedProduct.fotos.map((foto, index) => (
                            <div key={index}>
                              <input
                                type="text"
                                name="fotos"
                                value={foto}
                                onChange={(e) => handlePhotoChange(e, index)}
                              />
                              <button
                                onClick={() => removePhoto(index)}
                                className="remove-photo-btn"
                              >
                                Eliminar Foto
                              </button>
                            </div>
                          ))}
                          <button
                            onClick={addNewPhoto}
                            className="add-photo-btn"
                          >
                            Agregar Foto
                          </button>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="subCategory"
                            value={editedProduct.subCategory}
                            onChange={handleInputChange}
                          />
                        </td>
                        <td>
                          <button onClick={handleUpdate} className="save-btn">
                            Guardar
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{product.nombre}</td>
                        <td>{product.category}</td>
                        <td>{product.destacado ? "Sí" : "No"}</td>
                        <td>{product.desc.join(", ")}</td>
                        <td>{product.fotos.join(", ")}</td>
                        <td>{product.subCategory || "N/A"}</td>
                        <td>
                          <button
                            onClick={() => handleEdit(product)}
                            className="edit-btn"
                          >
                            Editar
                          </button>
                          <button
                            onClick={() => handleDelete(product.id)}
                            className="delete-btn"
                          >
                            Eliminar
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No hay productos disponibles.</p>
          )}
        </>
      )}
    </div>
  );
}

export default ManageProducts;
