import React from 'react'

export default function FixedContactButton() {
    return (
<>
    <a href="https://wa.link/1lrlbu" target="_blank">
        <div className="fixedContact">
            <img className="botonF1" src="https://img.icons8.com/office/256/whatsapp--v1.png" alt="logo-whatsapp" />
            <div className="text-contact-fixed">Contactame</div>
        </div>
    </a>
</>

    )
}
