import React from "react";
import "../styles/AboutMe.css";

export default function AboutMe() {
  return (
    <>
    <div className="centerBanner">
      <img
        className="banner-products"
        src="https://i.ibb.co/hBQSvs0/SOBRE-NOSOTROS.jpg"
        alt=""
      />
      </div>
      <div className="container-about-me">
        <img
          className="img-sobre-mi"
          src="https://i.ibb.co/nDTyCRn/sobre-mi.jpg"
          alt="foto-sobre-nosotros"
        />
        <div className="about-me">
          <p>
            Somos Sitel, una empresa con más de 50 años de experiencia en el
            mercado de las telecomunicaciones y la tecnología de comunicación.
            Desde nuestros inicios, hemos estado comprometidos en brindar
            soluciones innovadoras y de alta calidad en cables, módems, porteros
            eléctricos y mucho más.
          </p>
          <p>
            A lo largo de las décadas, hemos evolucionado junto con la
            tecnología, adaptándonos a los cambios y manteniendo siempre el
            enfoque en ofrecer productos que faciliten la comunicación y mejoren
            la conectividad de nuestros clientes en todo el país.
          </p>
          <p>
            Nuestro equipo está compuesto por expertos apasionados por la
            tecnología, siempre listos para brindar el mejor soporte técnico y
            asesoramiento personalizado. Nos enorgullece ser un referente en el
            sector, donde la confianza y la calidad son nuestras principales
            prioridades.
          </p>
          <p>
            Cada producto que ofrecemos es el resultado de años de experiencia y
            un profundo conocimiento del mercado. En Sitel, creemos en la
            importancia de la innovación y el desarrollo continuo, y estamos
            aquí para acompañarte en cada paso hacia la mejor solución de
            comunicación para tu hogar o negocio.
          </p>
          <p>
            Gracias a nuestros clientes por confiar en nosotros durante todos
            estos años. Seguimos comprometidos en ofrecer lo mejor en tecnología
            de comunicación, y estamos entusiasmados por lo que el futuro nos
            depara.
          </p>
          <p>
            Te invitamos a explorar nuestra gama de productos y a unirte a la
            familia Sitel, donde la conectividad y la excelencia están
            garantizadas.
          </p>
        </div>
      </div>
    </>
  );
}
