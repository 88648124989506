import React from 'react'
import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react';
import { FireContext } from '../context/FireContext';
import CardDetailProduct from './componentesProducts/CardDetailProduct.jsx'
import Loader from './Loader';

export default function DetailProduct() {
    const { itemId } = useParams()
    const { productos } = useContext(FireContext)
    const [itemDet, itemDetailId] = useState()

    useEffect(() => {
        if (productos) {
            const item = productos.find((producto) => {
                if (producto.id === itemId) {
                    itemDetailId(producto)
                }
            })
        }
    }, [itemId, productos])

    return (
        <>
            {itemDet ? <CardDetailProduct itemDet={itemDet} productos={productos} /> : <Loader/>}
        </>
    )
}
